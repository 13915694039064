import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

import Button from "../../components/Button";
import { useAuth } from "../../context/lib/auth";
import { useFeatureFlag } from "../../context/lib/flags";

function LoginScreen() {
  const { singIn, isLoading, error, isAuthenticated } = useAuth();
  const { register, handleSubmit } = useForm();
  const { flag_register } = useFeatureFlag();

  const handleSignIn = async (data: { email: string; password: string }) => {
    const res = await singIn(data);
    if (res) {
      toast.success("Login realizado com sucesso!");
      //router.push("/");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      // router.push("/");
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className={"bg-background dark:bg-background-dark "}>
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
          {/* aling in center with tailwind */}

          <div className="flex items-center justify-center h-screen">
            <div className="px-10 py-6 rounded bg-card w-96">
              <h1 className="my-4 text-2xl font-bold text-center text-dark ">
                Bem vindo ao sistema!
              </h1>
              <div className="flex flex-col  align-middle justify-center items-center">
                <p className="my-3 mb-5">Vamos começar?</p>

                <div className="flex flex-col py-2 gap-3.5 ">
                  <a href="/login" className="text-center">
                    <Button>
                      <p>Fazer Login</p>
                    </Button>
                  </a>

                  {flag_register && (
                    <a href="/register" className="text-center">
                      <Button alternative>
                        <p>Fazer Cadastro</p>
                      </Button>
                    </a>
                  )}

                  {flag_register && (
                    <a href="/dashboard" className="text-center">
                      <Button className="bg-transparent group-hover:text-light hover:bg-orange-200">
                        <p className="text-primary-900 roup">Balancetes</p>
                      </Button>
                    </a>
                  )}
                </div>
                <p className="mt-4"></p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
}

export default LoginScreen;

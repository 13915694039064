import { ReactElement } from "react";

import { transtionControl } from "../../globals/styles";

interface ButtonType {
  children: ReactElement;
  onClick?: () => void;
  className?: string;
  alternative?: boolean;
  type?: `fullCustom` | `submit`;
  disabled?: boolean;
  px?: number;
}

function Button(props: ButtonType) {
  const { children, className, disabled, type = "", px } = props;

  if (type === "submit") {
    return (
      <button
        disabled={disabled}
        className={`${
          props.alternative ? `bg-secondary-500` : `bg-primary-500`
        } px-14 pt-2 pb-2 rounded items-center cursor-pointer  ${transtionControl}${
          props.alternative ? `hover:bg-secondary-300` : `hover:bg-primary-400`
        } text-light font-bold ${disabled ? `opacity-50 cursor-wait` : ``} ${
          props?.className
        }`}
        type="submit"
      >
        {children}
      </button>
    );
  }
  if (type === "fullCustom") {
    return (
      <div
        onClick={props.onClick}
        className={`pt-2 pb-2 rounded items-center 
       cursor-pointer
        text-light font-bold 
        ${className}
        `}
      >
        {props.children}
      </div>
    );
  }

  return (
    <div
      onClick={props.onClick}
      className={`${
        props.alternative ? `bg-secondary-500` : `bg-primary-500`
      } ${px ? `px-${px}` : `px-14`} pt-2 pb-2 rounded items-center 
       cursor-pointer  ${transtionControl}
       ${props.alternative ? `hover:bg-secondary-300` : `hover:bg-primary-400`}
        text-light font-bold 
        ${className}
        `}
    >
      {props.children}
    </div>
  );
}

export default Button;

import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";

type ThemeContentType = {
  isDarkMode: boolean;
  changeTheme: (isDarkMode: boolean) => void;
  changeColor: () => void;
};

const ThemeContext = createContext({} as ThemeContentType);

export const ThemeProvider = ({ children }: { children: ReactElement }) => {
  const [isDarkMode, setisDarkMode] = useState<boolean>(true);
  useEffect(() => {
    loadTheme();
  }, []);

  async function loadTheme() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      setisDarkMode(true);
    } else {
      document.documentElement.classList.remove("dark");
      setisDarkMode(false);
    }
  }

  function changeColor() {
    changeTheme(!isDarkMode);
  }

  function changeTheme(isDarkMode: boolean) {
    setisDarkMode(isDarkMode);
    if (!isDarkMode) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
    localStorage.theme = isDarkMode ? "dark" : "light";
  }

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        changeTheme,
        changeColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

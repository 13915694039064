import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";

import Button from "../../components/Button";
import { useAuth } from "../../context/lib/auth";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "../../context/lib/flags";

function LoginScreen() {
  const { singIn, isLoading, error, isAuthenticated } = useAuth();
  const { register, handleSubmit } = useForm();

  const { flag_register } = useFeatureFlag();
  const navigate = useNavigate();

  const handleSignIn = async (data: { email: string; password: string }) => {
    await singIn({
      email: data.email,
      password: data.password,
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/balance");
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className={"bg-background dark:bg-background-dark "}>
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
          {/* aling in center with tailwind */}

          <div className="flex items-center justify-center h-screen">
            <div className="px-10 py-6 rounded bg-card w-96">
              <h1 className="my-4 text-2xl font-bold text-center text-dark ">
                Iniciar sessão
              </h1>
              <form
                className="mt-10"
                //@ts-ignore
                onSubmit={handleSubmit(handleSignIn)}
              >
                <div className="my-4">
                  <span className="block text-sm font-medium text-dark">
                    Email
                  </span>
                  <input
                    {...register("email")}
                    id="email"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  />
                </div>

                <div className="my-4">
                  <span className="block text-sm font-medium text-dark">
                    Senha
                  </span>
                  <input
                    {...register("password")}
                    type="password"
                    id="password"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 "
                  />
                </div>
                {error ? (
                  <h1 className="text-sm text-center text-red-600">
                    Dados incorretos
                  </h1>
                ) : null}
                <div className="py-4">
                  <Button
                    onClick={() => {}}
                    disabled={isLoading}
                    className={`w-full self-center text-center ${
                      isLoading ? "animate-pulse bg-primary-100" : ""
                    }`}
                    type="submit"
                  >
                    <h1>{isLoading ? `Carregando..` : `Entrar`}</h1>
                  </Button>
                  {flag_register && (
                    <Button className="mt-3 bg-secondary-600 hover:bg-secondary-700">
                      <a href={"/register"}>
                        <p className="text-light text-sm self-center text-center my-1.5 hover:text-light hover:cursor-pointer">
                          Criar conta
                        </p>
                      </a>
                    </Button>
                  )}
                </div>
                <a href={"/recovery"}>
                  <p className="text-dark   text-sm self-center text-center my-1.5 hover:text-primary-500 hover:cursor-pointer">
                    Esqueceu sua senha?
                  </p>
                </a>

                <a href="/">
                  <p className="text-dark   text-sm self-center text-center my-1.5 hover:text-primary-500 hover:cursor-pointer">
                    Voltar
                  </p>
                </a>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
}

export default LoginScreen;

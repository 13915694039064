/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import API, { SignIn } from "../../services/api";

import { useNavigate } from "react-router-dom";

type userData = {
  email: string;
  password: string;
} | null;

type userLoggued = {
  admin: number;
  id: number;
  name: string;
  email: string;
} | null;

type AuthContextType = {
  user: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  singIn: (props: userData) => Promise<any>;
  singOut: () => void;
  error: boolean;
  setUser: (user: any) => void;
};

const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [user, setUser] = useState<userLoggued>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>("");
  //error
  const [error, setError] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["auth.token"]);
  const [cookiesUser, setCookieUser] = useCookies(["auth.user"]);

  const navigate = useNavigate();
  useEffect(() => {
    loadUserFromCookies();
  }, []);

  useEffect(() => {
    API.interceptors.request.use(async (config) => {
      if (config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    setCookie("auth.token", token, { path: "/" });
  }, [token]);

  async function loadUserFromCookies() {
    const token = cookies["auth.token"];
    if (token) {
      setToken(token);
      setLoading(false);
    }
    const user = cookiesUser["auth.user"];
    if (user) {
      setUser(user);
    }
    setLoading(false);
  }

  const signIn = async (data_login: userData) => {
    setLoading(true);

    if (!data_login?.email || !data_login?.password) {
      setError(true);
      setLoading(false);
      return;
    }

    if (data_login) {
      //@ts-ignore
      const { error, data } = await SignIn(data_login);

      if (error) {
        setError(true);
        setLoading(false);
        return;
      }
      setCookie("auth.token", data.token, { path: "/" });
      setUser(data.user);
      setToken(data.token);
      setCookieUser("auth.user", data.user, { path: "/" });
      navigate("/dashboard");
    }

    setLoading(false);
    /*  setCookie("auth.token", "token", { path: "/" });
    setToken("token");
    setLoading(false); */
  };

  const singOut = () => {
    setUser(null);
    setToken(null);
    setError(false);
    setCookie("auth.token", "", { path: "/" });
    setCookieUser("auth.user", "", { path: "/" });
    return;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: token && user ? true : false,
        user,
        setUser,
        error,
        isLoading: loading,
        singOut,
        singIn: signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);


import axios from "axios";

const generateCSRF = () => {
    const csrfToken = document.querySelector("meta[name=csrf-token]");
    return csrfToken ? csrfToken.getAttribute("content") : "";
}
const base_url = 'https://consultar.alessandroferreira.dev/api'
const API = axios.create({
    baseURL: 'https://consultar.alessandroferreira.dev/api',
    // json
    headers: {
        'Content-Type': 'application/json',
        //‘X-CSRF-TOKEN’: $(‘meta[name=”csrf-token”]’).attr(‘content’)
    }
})

/*
[get] planocontas/
[post] planocontas/
[get] planocontas/:id
[post] planocontas/:id

'empresa' (int) - Required,
'codigo' (string) - Required,
'nome' (string) - Required,
'descricao' (string),
'tipo' (string),
'ativo' (boolean),

[get] empresas/
[get] empresas/:id
[post] empresas/:id
*/

// INTERCEPTOR
API.interceptors.request.use(async (config) => {
    let csrfToken = generateCSRF();
    if (csrfToken) {
        if(config.headers) {
            config.headers['X-CSRF-TOKEN'] = csrfToken;
        }
    }
    return config;
})

export const getPlanosContas = async () => {
    try {
        const response = await API.get('/planocontas');
        return response
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const getPlanosContasById = async (id: number | string) => {
    try {
        const response = await API.get(`/planocontas/${id}`);
        return response

    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const postPlanosContas = async (data: any) => {
    try {
        return await API.post('planocontas/', data)
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const putPlanosContas = async (id: number, data: any) => {
    try {
        return await API.put(`planocontas/${id}`, data)
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const getEmpresas = async () => {
    try {
        return await API.get('empresas/')
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const getEmpresasById = async (id: number|string) => {
    try {
        return await API.get(`empresas/${id}`)
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const postEmpresas = async (data: any) => {
    try {
        return await API.post('empresas/', data)
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const putEmpresas = async (id: number, data: any) => {
    try {
        return await API.put(`empresas/${id}`, data)
    }
    catch (error) {
        return {
            error: true,
            data: [],
            message: `${error}`
        }
    }
}

export const SignIn = async (data: {
    email: string,
    password: string
}) => {
    try {
        return await API.post(`${base_url}/login`, {
            email: `${data.email}`,
            password: `${data.password}`
        })
    }
    catch(e) {
        return {
            data: [],
            error: true
        }
    }
}

export const Resister = async (data: any) => {
    try {
        return await API.post(`${base_url}/register`, {
            email: `${data.email}`,
            password: `${data.password}`
        })
    }
    catch(e) {
        return await API.post('register/', data)
    }
}

export default API;
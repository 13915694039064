/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";

import { useNavigate } from "react-router-dom";
import { getPlanosContas, getPlanosContasById } from "../../services/api";

type PlansContextType = {
  getPlansPerId: (id: string | number) => Promise<any>;
  getPlans: () => Promise<any>;
  plansLoading: boolean;
  plans: any;
  plansError: boolean | null;
  uniquePlan: any;
  uniquePlanLoading: boolean;
  uniquePlanError: boolean | null;
};

const PlansContext = createContext({} as PlansContextType);

export const PlansProvider = ({ children }: { children: ReactElement }) => {
  const [plans, setPlans] = useState(null);
  const [plansLoading, setPlansLoading] = useState(true);
  const [plansError, setPlansError] = useState(null);

  const [uniquePlan, setUniquePlan] = useState(null);
  const [uniquePlanLoading, setUniquePlanLoading] = useState(true);
  const [uniquePlanError, setUniquePlanError] = useState(null);

  const [plansCookies, setPlansCookies] = useCookies(["plans"]);
  const [uniquePlanCookies, setUniquePlanCookies] = useCookies([
    "plans.unique",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    loadPlansFromCookies();
  }, []);

  useEffect(() => {
    if (plans) {
      setPlansCookies("plans", plans, { path: "/" });
    }
    if (uniquePlan) {
      setUniquePlanCookies("plans.unique", uniquePlan, { path: "/" });
    }
  }, [plans, uniquePlan]);

  const loadPlansFromCookies = async () => {
    const plans = plansCookies["plans"];
    if (plans) {
      setPlans(plans);
    }
    const uniquePlan = uniquePlanCookies["plans.unique"];
    if (uniquePlan) {
      setUniquePlan(uniquePlan);
    }
  };

  const getPlans = async () => {
    const response = await getPlanosContas();
    if (response.data) {
      setPlans(response.data);
    } else {
      // @ts-ignore
      setPlansError(response?.error);
    }
    setPlansLoading(false);
  };

  const getPlansPerId = async (id: string | number) => {
    const response = await getPlanosContasById(id);
    if (response.data) {
      setUniquePlan(response.data);
    } else {
      // @ts-ignore
      setUniquePlanError(response?.error);
    }
    setUniquePlanLoading(false);
  };

  return (
    <PlansContext.Provider
      value={{
        getPlansPerId,
        getPlans,
        plansLoading,
        plans,
        plansError,
        uniquePlan,
        uniquePlanLoading,
        uniquePlanError,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

export const usePlans = () => useContext(PlansContext);

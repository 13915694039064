import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useCompany } from "../../../context/lib/company";
import { usePlans } from "../../../context/lib/plans";
import {
  FaBalanceScale,
  FaBalanceScaleLeft,
  FaHome,
  FaList,
  FaUser,
} from "react-icons/fa";
import Sidebar from "../../../components/SideBarAtt/partials/Sidebar";
import { ToastContainer } from "react-toastify";
import Banner from "../../../components/SideBarAtt/partials/Banner";
import Header from "../../../components/SideBarAtt/partials/Header";
import { useAuth } from "../../../context/lib/auth";
import Button from "../../../components/Button";

function CompanyScreenCreate() {
  const { getCompanys, Company, CompanyLoading } = useCompany();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const [form, setForm] = useState({
    Nome: "",
    CNPJ: "",
    "E-mail": "",
    Telefone: "",
    Tipo: "",
  });

  useLayoutEffect(() => {
    getCompanys();
  }, []);

  useEffect(() => {}, []);

  console.log(Company);
  const menus = useMemo(
    () => [
      {
        name: "Home",
        icon: (size: number) => <FaHome size={size} />,
        href: "/dashboard",
      },
      {
        name: "Enviar balanço",
        onPress: () => {},
        href: "/balance",
        icon: (size: number) => <FaBalanceScale size={size} />,
      },
      {
        name: "Empresas",
        onPress: () => {},
        icon: (size: number) => <FaHome size={size} />,
        // href: "/company",
        subMenus: [
          {
            name: "Listar",
            onPress: () => {},
            href: "/company",
            icon: (size: number) => <FaList size={size} />,
          },
          {
            name: "Cadastrar",
            onPress: () => {},
            href: "/company/create",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Editar",
            onPress: () => {},
            href: "/company/edit",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Excluir",
            onPress: () => {},
            href: "/company/delete",
            icon: (size: number) => <FaUser size={size} />,
          },
        ],
      },
      {
        name: `Planos`,
        onPress: () => {},
        icon: (size: number) => <FaList size={size} />,
        href: "/plans",
      },
      {
        name: `Usuarios`,
        onPress: () => {},
        icon: (size: number) => <FaUser size={size} />,
        href: "/users",
      },
      {
        name: `Relatórios`,
        onPress: () => {},
        icon: (size: number) => <FaBalanceScaleLeft size={size} />,
        href: "/reports",
      },
    ],
    []
  );

  const renderForm = () => {
    const cellOfForm = (label: string, name: string, type: string) => {
      return (
        <div className="flex flex-col w-full my-1">
          <label className="text-dark mb-0.5">{label}</label>
          <input
            type={type}
            name={name}
            //@ts-ignore
            value={form[name]}
            onChange={(e) => {
              setForm({ ...form, [name]: e.target.value });
            }}
            className="border-2 border-gray-300 p-2 rounded-lg mt-1 focus:outline-none focus:border-indigo-500"
          />
        </div>
      );
    };

    return (
      <div className="flex flex-col">
        {Object.entries(form).map(([key, value]) => {
          return cellOfForm(key, key, "text");
        })}
        <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
          <Button
            onClick={() => {
              console.log(form);
            }}
            className="px-12 py-1 text-white font-light tracking-wider bg-gray-900 rounded"
          >
            <p>Criar</p>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        menus={menus}
      />

      <div className="flex w-full justify-center align-middle items-center bg-background">
        <div className="max-w-screen-xl w-full  ">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Banner />
          <div className="flex items-center justify-center h-screen">
            <div className="px-10 py-6 rounded bg-card w-96">
              <h1 className="my-4 text-2xl font-bold text-center text-dark ">
                {CompanyLoading ? "Carregando..." : "Criar empresa"}
              </h1>
              {renderForm()}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default CompanyScreenCreate;

/* eslint-disable react-hooks/exhaustive-deps */
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

import Button from "../../components/Button";
import { useAuth } from "../../context/lib/auth";

function RegisterScreen() {
  const sinopse = `Pagina de login de Slime Read.`;
  const title = `Register - SlimeRead - Slime Read, venha ler suas obras favoritas aqui!`;
  const { singIn, isLoading, isAuthenticated } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [validRecaptchabe, setValidRecaptchabe] = useState(false);
  const [otherErros, setOtherErros] = useState(``);

  const onReCAPTCHAChange = (captchaCode: any) => {
    if (!captchaCode) {
      setValidRecaptchabe(false);
      return;
    }
    setValidRecaptchabe(true);
  };

  const handlerRegister = async (data: {
    nickname: string;
    password: string;
    email: string;
    password_confirmation: string;
  }) => {
    const { nickname, password, email, password_confirmation } = data;
    setOtherErros(``);

    /* if (!validRecaptchabe) {
      setOtherErros(`Preencha o reCAPTCHA`);
      return;
    } */
    toast.info("Ainda não implementado");
    return;
    /* 
    if (!email) {
      toast.error("Ops.. Email invalido.");
      return;
    }
    if (!nickname) {
      toast.error("Ops.. Insira um apelido valido.");
      return;
    }
    if (password !== password_confirmation) {
      toast.error("Ops.. As senhas não coincidem.");
      setOtherErros(`As senhas não coincidem.`);
      return;
    }

    //@ts-ignore
    const { error, message, user } = await registerUserAPI({
      nickname,
      password,
      email,
    });

    if (message) {
      setOtherErros([message] ? [message] : message);
      return;
    }

    if (!error) {
      singIn({ email: nickname, password });
      //   router.push(`/`);
      toast.success("Cadastro realizado com sucesso!");
    } */
  };

  useEffect(() => {
    if (isAuthenticated) {
      //    router.push("/");
    }
  }, []);

  return (
    <>
      <div className={"bg-background dark:bg-background-dark "}>
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
          {/* aling in center with tailwind */}

          <div className="flex items-center justify-center h-screen">
            <div className="px-10 py-6 rounded bg-card w-96">
              <h1 className="text-2xl font-bold text-center dark-text my- ">
                Realizar Cadastro
              </h1>
              <form
                className="mt-10"
                // @ts-ignore
                onSubmit={handleSubmit(handlerRegister)}
              >
                <div className="my-4">
                  <span className="block text-sm font-medium text-dark ">
                    Email
                  </span>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    id="email"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 "
                  />
                </div>

                <div className="my-4">
                  <span className="block text-sm font-medium text-dark ">
                    Nickname
                  </span>
                  <input
                    {...register("nickname", { required: true })}
                    type="text"
                    id="nickname"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 "
                  />
                </div>

                <div className="my-4">
                  <span className="block text-sm font-medium text-dark ">
                    Senha
                  </span>
                  <input
                    {...register("password", { required: true })}
                    type="password"
                    id="password"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 "
                  />
                </div>

                <div className="my-4">
                  <span className="block text-sm font-medium text-dark ">
                    Repita sua senha
                  </span>
                  <input
                    {...register("password_confirmation", { required: true })}
                    type="password"
                    id="password_confirmation"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 "
                  />
                </div>

                {/* <Recapchaba onChange={onReCAPTCHAChange} /> */}
                <div className="text-left  text-sm text-red-500 flex flex-col mt-2.5">
                  {/*  <p>
                    {errors?.email?.type === "required" &&
                      "* O campo de 'Email' é obrigatorio\n"}{" "}
                  </p>
                  <p>
                    {errors?.nickname?.type === "required" &&
                      "* O campo 'Nickname' é obrigatorio\n"}
                  </p>

                  <p>
                    {errors?.password?.type === "required" &&
                      "* O campo 'Senha' é obrigatorio\n"}
                  </p>
                  <p>
                    {errors?.password_confirmation?.type === "required" &&
                      "* O campo 'Repita sua senha' é obrigatorio\n"}
                  </p> */}
                  <p>{otherErros}</p>
                </div>
                <div className="py-4">
                  <Button
                    onClick={() => {}}
                    disabled={isLoading}
                    className={`w-full self-center text-center ${
                      isLoading ? "animate-pulse bg-primary-100" : ""
                    }`}
                    type="submit"
                  >
                    <h1>{isLoading ? `Carregando..` : `Cadastrar`}</h1>
                  </Button>
                </div>
                <a href={"/login"}>
                  <p className="text-dark   text-sm self-center text-center my-1.5 hover:text-primary-500 hover:cursor-pointer">
                    Voltar{" "}
                  </p>
                </a>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
}

export default RegisterScreen;

function registerUserAPI(arg0: {
  nickname: string;
  password: string;
  email: string;
}) {
  // return
}

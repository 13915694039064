import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CompanyScreen from "./company";
import { Providers } from "../context";
import Home from "./conversor/app";
import DashBoardScreen from "./dashboard";
import LoginScreen from "./login";
import OnboardingScreen from "./onboarding";
import PendingScreen from "./pending";
import PlansScreen from "./plans";
import RecoveryScreen from "./recovery";
import RegisterScreen from "./register";
import CompanyScreenEdit from "./company/edit";
import CompanyScreenCreate from "./company/create";
import CompanyScreenDelete from "./company/delete";
import UsersScreen from "./users";

function App() {
  return (
    <Router>
      <Providers>
        <div className="App">
          <Routes>
            <Route path="/" element={<OnboardingScreen />} />
            <Route path="/login" element={<LoginScreen />}></Route>
            <Route path="/balance" element={<Home />}></Route>
            <Route path="/register" element={<RegisterScreen />}></Route>
            <Route path="/recovery" element={<RecoveryScreen />}></Route>
            <Route path="/dashboard" element={<DashBoardScreen />}></Route>
            <Route path="/company" element={<CompanyScreen />}></Route>
            <Route path="/company/list" element={<CompanyScreen />}></Route>
            <Route path="/plans" element={<PlansScreen />}></Route>
            <Route path="/users" element={<UsersScreen />}></Route>
            <Route path="/company/edit" element={<CompanyScreenEdit />}></Route>
            <Route
              path="/company/create"
              element={<CompanyScreenCreate />}
            ></Route>
            <Route
              path="/company/delete"
              element={<CompanyScreenDelete />}
            ></Route>

            <Route path="*" element={<PendingScreen />}></Route>
          </Routes>
        </div>
      </Providers>
    </Router>
  );
}

export default App;

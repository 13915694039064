/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from "react";

type FeatureFlagsType = {
  flag_register: boolean;
};

const FeatureFlags = createContext({} as FeatureFlagsType);

export const FeatureFlagsProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [flag_register] = useState(false);

  return (
    <FeatureFlags.Provider
      value={{
        flag_register,
      }}
    >
      {children}
    </FeatureFlags.Provider>
  );
};

export const useFeatureFlag = () => useContext(FeatureFlags);

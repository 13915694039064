import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";

import Button from "../../components/Button";
import { useAuth } from "../../context/lib/auth";

function RecoveryScreen() {
  const { isAuthenticated } = useAuth();
  const { register, handleSubmit } = useForm();
  const [otherErros, setOtherErros] = useState(``);
  const [statusSended, setStatusSended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validRecaptchabe, setValidRecaptchabe] = useState(false);

  const onReCAPTCHAChange = (captchaCode: any) => {
    if (!captchaCode) {
      setValidRecaptchabe(false);
      return;
    }
    setValidRecaptchabe(true);
  };

  const handleRecovery = async (data: { email: string }) => {
    /*   if (!validRecaptchabe) {
      setOtherErros(`Preencha o reCAPTCHA`);
      return;
    }
 */
    const { email } = data;
    setOtherErros(``);

    if (!email) {
      toast.error("Ops.. Email invalido.");
      return;
    }
    setIsLoading(true);
    await recoveryUserAPI({ email });
    setIsLoading(false);
    setStatusSended(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      ///  router.push("/");
    }
  }, []);

  return (
    <>
      <div className={"bg-background dark:bg-background-dark "}>
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
          {/* aling in center with tailwind */}

          <div className="flex items-center justify-center h-screen">
            <div className="px-10 py-6 rounded bg-card w-96">
              <h1 className="text-2xl font-bold text-center dark-text ">
                Recuperar senha
              </h1>
              <form
                className="mt-10"
                //@ts-ignore
                onSubmit={handleSubmit(handleRecovery)}
              >
                <div className="my-4">
                  <span className="block text-sm font-medium text-dark ">
                    Email
                  </span>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    id="email"
                    className="block w-full px-3 py-2 mt-1 text-sm bg-white border rounded-md shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                  />
                </div>

                {/*                 <Recapchaba onChange={onReCAPTCHAChange} />
                 */}
                {otherErros ? (
                  <h1 className="mt-3 text-sm text-center text-red-600 ">
                    {otherErros}
                  </h1>
                ) : null}
                {statusSended ? (
                  <h1 className="mt-3 text-sm text-center text-green-500 ">
                    Caso o email exista, você receberá um email com instruções
                    para recuperar sua senha.
                  </h1>
                ) : null}

                <div className="py-4">
                  {statusSended ? null : (
                    <Button
                      onClick={() => {}}
                      disabled={isLoading}
                      className={`w-full self-center text-center ${
                        isLoading ? "animate-pulse bg-primary-100" : ""
                      }`}
                      type="submit"
                    >
                      <h1>{isLoading ? `Enviando..` : `Recuperar`}</h1>
                    </Button>
                  )}
                </div>
                <a href="/login">
                  <p className="text-dark text-sm self-center text-center my-1.5 hover:text-primary-500 hover:cursor-pointer">
                    Voltar{" "}
                  </p>
                </a>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
}

export default RecoveryScreen;
function recoveryUserAPI(arg0: { email: string }) {
  toast.info("Não implementado ainda.");
}

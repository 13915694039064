/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";

import { useNavigate } from "react-router-dom";
import {
  getEmpresas,
  getEmpresasById,
  getPlanosContas,
  getPlanosContasById,
} from "../../services/api";

type CompanyContextType = {
  getCompanysPerId: (id: string | number) => Promise<any>;
  getCompanys: () => Promise<any>;
  CompanyLoading: boolean;
  Company: any;
  CompanyError: boolean | null;
  uniqueCompany: any;
  uniqueCompanyLoading: boolean;
  uniqueCompanyError: boolean | null;
};

const CompanyContext = createContext({} as CompanyContextType);

export const CompanyProvider = ({ children }: { children: ReactElement }) => {
  const [Company, setCompany] = useState(null);
  const [CompanyLoading, setCompanyLoading] = useState(true);
  const [CompanyError, setCompanyError] = useState(null);

  const [uniqueCompany, setuniqueCompany] = useState(null);
  const [uniqueCompanyLoading, setuniqueCompanyLoading] = useState(true);
  const [uniqueCompanyError, setuniqueCompanyError] = useState(null);

  const [CompanyCookies, setCompanyCookies] = useCookies(["company"]);
  const [uniqueCompanyCookies, setuniqueCompanyCookies] = useCookies([
    "company.unique",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    loadCompanyFromCookies();
  }, []);

  useEffect(() => {
    if (Company) {
      setCompanyCookies("company", Company, { path: "/" });
    }
    if (uniqueCompany) {
      setuniqueCompanyCookies("company.unique", uniqueCompany, { path: "/" });
    }
  }, [Company, uniqueCompany]);

  const loadCompanyFromCookies = async () => {
    const Company = CompanyCookies["company"];
    if (Company) {
      setCompany(Company);
    }
    const uniqueCompany = uniqueCompanyCookies["company.unique"];
    if (uniqueCompany) {
      setuniqueCompany(uniqueCompany);
    }
  };

  const getCompanys = async () => {
    const response = await getEmpresas();
    if (response.data) {
      setCompany(response.data);
    } else {
      // @ts-ignore
      setCompanyError(response?.error);
    }
    setCompanyLoading(false);
  };

  const getCompanysPerId = async (id: string | number) => {
    const response = await getEmpresasById(id);
    if (response.data) {
      setuniqueCompany(response.data);
    } else {
      // @ts-ignore
      setuniqueCompanyError(response?.error);
    }
    setuniqueCompanyLoading(false);
  };

  return (
    <CompanyContext.Provider
      value={{
        getCompanysPerId,
        getCompanys,
        CompanyLoading,
        Company,
        CompanyError,
        uniqueCompany,
        uniqueCompanyLoading,
        uniqueCompanyError,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);

import { json } from "../pages/conversor/app";

export const saveJsonInXLSX = (file: any, mapkeyToNewKey: any, options?: {
    removeCD: boolean,
}) => {
    const newFile = file.map((item: any) => {
        // coloca todo os outros valores do objeto no novo objeto
        let newItem = { ...item };

        // coleta todas as chaves originais
        let original_key = Object.keys(item);
        // verifica se pra chave original existe algum valor modificado dentro do mapkeyToNewKey
        original_key.forEach((key, index_original) => {
            // procura a chave modificada baseado no key original
            const newKey = Object.entries(mapkeyToNewKey).find(([k, v]) => k === key);
            if (typeof item[key] === 'string') {
                // se existis removeCD
                // checa se string contem ' D' ou ' C'
                // D = debito, float negativo
                // C = credito, float positivo
                let newValue = item[key];
                if (options?.removeCD === true && (newValue.includes(' D') || newValue.includes(' C'))) {
                    let correctionFactor = item[key].includes(' D') ? -1 : 1

                    let newValue = item[key].replace(' D', '').replace(' C', '');
                    newValue = newValue.replace(/\./g, '').replace(',', '.')
                    newValue = parseFloat(newValue) * correctionFactor;

                    if (!Number.isNaN(newValue)) {
                        newValue = newValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                        item[key] = newValue;
                    }
                }

            }
            // se existe a chave modificada, alterar o valor e excluir o key original
            if (newKey) {
                //@ts-ignore
                newItem[newKey[1]] = item[key];
                delete newItem[key];
            }
        })
        return newItem;
    })
    return newFile;
}

//@ts-ignore
export const processCSV = (str, delim = ',', removecd= false) => {
    // regex for , and ;
    let regex = /,|;/g;

    const headers = str.slice(0, str.indexOf('\n')).split(regex);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    
    //@ts-ignore
    const newArray = rows.map(row => {
        const values = row.split(delim);
        //@ts-ignore
        const eachObject = headers.reduce((obj, header, i) => {
            let value_to_compare = values[i];
            if(value_to_compare) {
                // remove ' and "",
                value_to_compare = value_to_compare.replace(/['"]+/g, '');
            }   

            console.log(header)
            // DÉBITO e CRÉDITO
            if(value_to_compare && (header === 'DÉBITO' || header === 'CRÉDITO')) {
                // REMOVE .
                value_to_compare = value_to_compare.replace(/\./g, '');
                // REMOVE ,
                value_to_compare = value_to_compare.replace(/,/g, '');
                let replaced =
                value_to_compare.substring(0, value_to_compare.length - 2) +
                    ',' +
                    value_to_compare.substring(value_to_compare.length - 2, value_to_compare.length);

                value_to_compare = replaced;
            }
            obj[header] = value_to_compare;

                if(removecd && value_to_compare  && (value_to_compare.includes(' D') || value_to_compare.includes(' C'))){
                    console.log('entrou')
                    console.log(value_to_compare)
                    console.log(value_to_compare.includes(' D'))
                    console.log(value_to_compare.includes(' C'))
    
                    let correctionFactor = obj[header].includes(' D') ? -1 : 1
                    console.log(obj[header])
                    console.log(correctionFactor)
                    // remove ""
                    value_to_compare = value_to_compare.replace(/\"/g, '');
                    console.log(value_to_compare)
                    let newValue = obj[header].replace(' D', '').replace(' C', '');
                    console.log(newValue)
                    newValue = newValue.replace(/\./g, '').replace(',', '.')
                    // REMOVE SPACES
                    newValue = newValue.replace(/\s/g, '').replace(/\"/g, '');
                    console.log(typeof newValue, newValue)
                    // REPLACE the last 3 chars with .\
                    let replaced =
                    newValue.substring(0, newValue.length - 2) +
                        ',' +
                        newValue.substring(newValue.length - 2, newValue.length);

                    console.log(`replaced`, replaced)
                    newValue = `${replaced}`;
                    console.log(newValue)

                    console.log(`newValue: ${newValue}`)
                    if (!Number.isNaN(parseFloat(newValue))) {
                        newValue = newValue.replace(/\"/g, '');
                        newValue = newValue.toLocaleString('pt-BR', { style:
                            'currency', currency: 'BRL' });

                        obj[header] = newValue;
                    }
                    
            }
            return obj;
        }, {})
        return eachObject;
    })
    console.log(newArray);

    return newArray;
}
export const proccessJSONTEXTtoCSV = (jsonText: string): string => {

    let csv_to_converted = `Conta	Descricao	Saldo Anteri	or	Debito	Credito	Saldo Atu	al	Resulta	do
    1.0.00.00.00.00000	ATIVO	92.855,26	D	551.982,36	420.114,21	224.723,41	D	131.868,15	D
    1.1.00.00.00.00000	ATIVO CIRCULANTE	54.918,47	D	477.413,26	399.487,11	132.844,62	D	77.926,15	D
    1.1.01.00.00.00000	DISPONIBILIDADES	21.134,59	D	265.535,61	181.000,36	105.669,84	D	84.535,25	D
    1.1.01.01.00.00000	CAIXAS E BANCOS	21.134,59	D	265.535,61	181.000,36	105.669,84	D	84.535,25	D
    1.1.01.01.01.00000	CAIXA GERAL	23.111,17	D	73.970,08	75.019,11	22.062,14	D	1.049,03	C
    1.1.01.01.01.00001	CAIXA	23.111,17	D	73.970,08	75.019,11	22.062,14	D	1.049,03	C
    1.1.01.01.02.00000	BANCOS CONTA M	0		191.565,53	105.981,25	85.584,28	D	85.584,28	D
    1.1.01.01.02.00001	BANCO ITAU S/	0		59.576,16	45.691,06	13.885,10	D	13.885,10	D
    1.1.01.01.02.00014	BANCO SANTAND	0		21.703,82	60.290,19	38.586,37	C	38.586,37	C
    1.1.01.01.02.00029	BANCO PINE SA	0		110.285,55	0	110.285,55	D	110.285,55	D
    1.1.01.01.03.00000	(-) CHEQUES EM	1.976,58	C	0	0	1.976,58	C	0	
    1.1.01.01.03.00001	(-) BANCO SAN	1.976,58	C	0	0	1.976,58	C	0	
    1.1.02.00.00.00000	REALIZAVEL A CUR	33.783,88	D	211.877,65	218.486,75	27.174,78	D	6.609,10	C
    1.1.02.02.00.00000	CONTAS A RECEBE	20.450,43	D	191.074,65	196.453,27	15.071,81	D	5.378,62	C
    1.1.02.02.01.00000	VALE TRANSPORT	20.450,43	D	191.074,65	196.453,27	15.071,81	D	5.378,62	C
    1.1.02.02.01.00006	VALE ELETRONI	20.450,43	D	191.074,65	196.453,27	15.071,81	D	5.378,62	C
    1.1.02.03.00.00000	ADIANTAMENTOS	13.333,45	D	20.803,00	22.033,48	12.102,97	D	1.230,48	C
    1.1.02.03.01.00000	ADIANTAMENTOS	67,51	D	0	0	67,51	D	0	
    1.1.02.03.01.00002	ADIANTAMENTO	67,51	D	0	0	67,51	D	0	
    1.1.02.03.03.00000	ADIANTAMENTO A	13.265,94	D	20.803,00	22.033,48	12.035,46	D	1.230,48	C
    1.1.02.03.03.00001	ADIANTAMENTO	0		20.803,00	20.803,00	0		0	
    1.1.02.03.03.00003	EMPRESTIMOS A	10.654,60	D	0	612,8	10.041,80	D	612,8	C
    1.1.02.03.03.00004	ADIANTAMENTO	2.611,34	D	0	617,68	1.993,66	D	617,68	C
    1.2.00.00.00.00000	ATIVO N�O CIRCULA	37.936,79	D	74.569,10	20.627,10	91.878,79	D	53.942,00	D
    1.2.01.00.00.00000	DIREITO E CREDIT	24.931,54	D	74.569,10	20.229,22	79.271,42	D	54.339,88	D
    1.2.01.01.00.00000	DIREITO E CREDI	24.931,54	D	74.569,10	20.229,22	79.271,42	D	54.339,88	D
    1.2.01.01.03.00000	DEPOSITOS JUDI	24.931,54	D	0	0	24.931,54	D	0	
    1.2.01.01.03.00258	ALAN MACARIO	14.116,22	D	0	0	14.116,22	D	0	
    1.2.01.01.03.00276	DIONIZIO MORE	5.000,00	D	0	0	5.000,00	D	0	
    1.2.01.01.03.00282	INSS UNIMED J	5.815,32	D	0	0	5.815,32	D	0	
    1.2.01.01.04.00000	EMPRESTIMOS EM	0		74.569,10	20.229,22	54.339,88	D	54.339,88	D
    1.2.01.01.04.00012	TERRITORIAL T	0		0	450,01	450,01	C	450,01	C
    1.2.01.01.04.00020	TERRITORIAL T	0		74.569,10	19.779,21	54.789,89	D	54.789,89	D
    1.2.02.00.00.00000	INVESTIMENTOS	13.005,25	D	0	397,88	12.607,37	D	397,88	C
    1.2.02.02.00.00000	ATIVO IMOBILIZA	13.005,25	D	0	397,88	12.607,37	D	397,88	C
    1.2.02.02.01.00000	IMOBILIZADO	26.819,28	D	0	0	26.819,28	D	0	
    1.2.02.02.01.00010	HARDWARE / EQ	26.819,28	D	0	0	26.819,28	D	0	
    1.2.02.02.03.00000	(-) DEPRECIA��	13.814,03	C	0	397,88	14.211,91	C	397,88	C
    1.2.02.02.03.00008	(-) DEPREC. A	13.814,03	C	0	397,88	14.211,91	C	397,88	C
    1.2.02.03.00.00000	ATIVO INTANGIVE	0		0	0	0		0	
    1.2.02.03.01.00000	INTANGIVEL	524,72	D	0	0	524,72	D	0	
    1.2.02.03.01.00002	SOFTWARE / PR	524,72	D	0	0	524,72	D	0	
    1.2.02.03.02.00000	(-) AMORTIZA��	524,72	C	0	0	524,72	C	0	
    1.2.02.03.02.00002	(-) AMORT. AC	524,72	C	0	0	524,72	C	0	
    2.0.00.00.00.00000	PASSIVO	92.855,26	C	415.093,76	421.103,23	98.864,73	C	6.009,47	C
    2.1.00.00.00.00000	PASSIVO CIRCULANT	489.312,20	C	415.093,76	420.090,09	494.308,53	C	4.996,33	C
    2.1.01.00.00.00000	EXIGIVEL A CURTO	489.312,20	C	415.093,76	420.090,09	494.308,53	C	4.996,33	C
    2.1.01.02.00.00000	FORNECEDORES	65.373,20	C	36.168,78	36.317,01	65.521,43	C	148,23	C
    2.1.01.02.01.00000	FORNECEDORES	65.373,20	C	36.168,78	36.317,01	65.521,43	C	148,23	C
    2.1.01.02.01.00036	BIO OCUPACION	0		120,76	120,76	0		0	
    2.1.01.02.01.00057	CEMIG DISTRIB	254,5	C	254,5	158,22	158,22	C	96,28	D
    2.1.01.02.01.00073	COPASA COMPAN	1.595,62	C	1.595,62	1.546,79	1.546,79	C	48,83	D
    2.1.01.02.01.00118	FORNECEDORES	0		2.610,99	2.610,99	0		0	
    2.1.01.02.01.00284	UNIMED BH- CO	11.761,55	C	11.761,48	11.347,69	11.347,76	C	413,79	D
    2.1.01.02.01.00291	ALELO S/A	47.072,35	C	16.226,62	15.395,62	46.241,35	C	831	D
    2.1.01.02.01.00406	BGM RODOTEC T	1.491,03	C	1.491,03	1.491,03	1.491,03	C	0	
    2.1.01.02.01.00409	MODERNIZAR LT	399,23	C	399,23	399,31	399,31	C	0,08	C
    2.1.01.02.01.01059	CTB MULTIMIDI	892,02	C	450,01	455,8	897,81	C	5,79	C
    2.1.01.02.01.01514	MEDIFONO ASSE	0		333,85	333,85	0		0	
    2.1.01.02.01.01688	TELEFONICA BR	1.550,50	C	0	1.550,50	3.101,00	C	1.550,50	C
    2.1.01.02.01.02309	NOTRE DAME IN	0		636,59	636,59	0		0	
    2.1.01.02.01.02373	UNIAO SEGURAD	356,4	C	178,2	159,96	338,16	C	18,24	D
    2.1.01.02.01.02386	NC1 TECNOLOGI	0		109,9	109,9	0		0	
    2.1.01.05.00.00000	OBRIGACOES TRAB	351.054,52	C	114.776,52	114.386,87	350.664,87	C	389,65	D
    2.1.01.05.01.00000	SALARIOS E ORD	44.072,67	C	83.351,95	74.966,01	35.686,73	C	8.385,94	D
    2.1.01.05.01.00001	SALARIOS E OR	34.916,08	C	71.669,13	66.912,34	30.159,29	C	4.756,79	D
    2.1.01.05.01.00006	FERIAS A PAGA	0		5.937,07	8.053,67	2.116,60	C	2.116,60	C
    2.1.01.05.01.00008	PROVIS�O DE B	9.156,59	C	5.745,75	0	3.410,84	C	5.745,75	D
    2.1.01.05.02.00000	OUTRAS OBRIGAC	6.349,96	C	4.940,63	5.156,68	6.566,01	C	216,05	C
    2.1.01.05.02.00001	CONVENIO BENE	638,45	C	1.581,60	1.444,97	501,82	C	136,63	D
    2.1.01.05.02.00002	PENSAO ALIMEN	575,52	C	528	629,28	676,8	C	101,28	C
    2.1.01.05.02.00003	CONVENIO ALIM	216	C	216	277,5	277,5	C	61,5	C
    2.1.01.05.02.00008	CONTRIBUI��O	2.304,96	C	0	643,5	2.948,46	C	643,5	C
    2.1.01.05.02.00009	EMPRESTIMO CO	2.615,03	C	2.615,03	2.161,43	2.161,43	C	453,6	D
    2.1.01.05.03.00000	ENCARGOS SOCIA	152.127,29	C	2.432,27	18.984,06	168.679,08	C	16.551,79	C
    2.1.01.05.03.00001	INSS	103.608,94	C	1.466,99	12.096,13	114.238,08	C	10.629,14	C
    2.1.01.05.03.00002	FGTS	29.490,95	C	0	5.561,63	35.052,58	C	5.561,63	C
    2.1.01.05.03.00006	ATUALIZA��ES	17.489,09	C	0	1.326,30	18.815,39	C	1.326,30	C
    2.1.01.05.03.00013	INSS BANCO DE	805,8	C	505,62	0	300,18	C	505,62	D
    2.1.01.05.03.00014	FGTS BANCO DE	732,51	C	459,66	0	272,85	C	459,66	D
    2.1.01.05.04.00000	PROVISAO PARA	145.844,56	C	24.051,67	9.466,49	131.259,38	C	14.585,18	D
    2.1.01.05.04.00001	PROVISAO PARA	126.491,38	C	22.020,14	9.370,37	113.841,61	C	12.649,77	D
    2.1.01.05.04.00002	FGTS S/FERIAS	10.119,31	C	1.011,98	0	9.107,33	C	1.011,98	D
    2.1.01.05.04.00003	INSS S/FERIAS	9.233,87	C	1.019,55	96,12	8.310,44	C	923,43	D
    2.1.01.05.05.00000	PROVISAO PARA	0		0	5.813,63	5.813,63	C	5.813,63	C
    2.1.01.05.05.00001	PROVISAO PARA	0		0	5.042,18	5.042,18	C	5.042,18	C
    2.1.01.05.05.00002	INSS S/13� SA	0		0	368,08	368,08	C	368,08	C
    2.1.01.05.05.00003	FGTS S/13� SA	0		0	403,37	403,37	C	403,37	C
    2.1.01.05.06.00000	TAXAS E CONTRI	2.660,04	C	0	0	2.660,04	C	0	
    2.1.01.05.06.00001	CONTRIBUICAO	569,34	C	0	0	569,34	C	0	
    2.1.01.05.06.00003	CONTRIBUICAO	1.709,95	C	0	0	1.709,95	C	0	
    2.1.01.05.06.00004	MENSALIDADES	380,75	C	0	0	380,75	C	0	
    2.1.01.06.00.00000	IMPOSTOS TAXAS	72.884,48	C	4.229,64	9.467,39	78.122,23	C	5.237,75	C
    2.1.01.06.01.00000	IMPOSTOS TAXAS	69.344,11	C	689,27	6.320,21	74.975,05	C	5.630,94	C
    2.1.01.06.01.00001	IRPF S/SALARI	437,95	C	338,9	396,18	495,23	C	57,28	C
    2.1.01.06.01.00004	IRPJ S/ SERVI	122,14	C	85,45	37,54	74,23	C	47,91	D
    2.1.01.06.01.00013	CSLL RETIDO S	81,4	C	56,97	25,03	49,46	C	31,94	D
    2.1.01.06.01.00014	PIS RETIDO S/	52,91	C	37,04	16,27	32,14	C	20,77	D
    2.1.01.06.01.00015	COFINS RETIDO	244,26	C	170,91	75,09	148,44	C	95,82	D
    2.1.01.06.01.00029	INSS FATURAME	57.506,12	C	0	5.193,67	62.699,79	C	5.193,67	C
    2.1.01.06.01.00038	ATUALIZA��ES	10.899,33	C	0	576,43	11.475,76	C	576,43	C
    2.1.01.06.02.00000	IMPOSTOS E TAX	3.540,37	C	3.540,37	3.147,18	3.147,18	C	393,19	D
    2.1.01.06.02.00002	ICMS	3.414,49	C	3.414,49	3.019,86	3.019,86	C	394,63	D
    2.1.01.06.02.00003	I.S.S S/SERVI	125,88	C	125,88	127,32	127,32	C	1,44	C
    2.1.01.08.00.00000	RECEITAS APROPR	0		259.918,82	259.918,82	0		0	
    2.1.01.08.02.00000	RECEITAS APROP	0		259.918,82	259.918,82	0		0	
    2.1.01.08.02.00001	RECEBIMENTO D	0		259.918,82	259.918,82	0		0	
    2.2.00.00.00.00000	PASSIVO NAO CIRCU	368.827,58	C	0	1.013,14	369.840,72	C	1.013,14	C
    2.2.01.00.00.00000	PASSIVO NAO CIRC	368.827,58	C	0	1.013,14	369.840,72	C	1.013,14	C
    2.2.01.01.00.00000	EMPRESTIMOS / F	37.105,13	C	0	0	37.105,13	C	0	
    2.2.01.01.02.00000	EMPREST. EMPRE	37.105,13	C	0	0	37.105,13	C	0	
    2.2.01.01.02.00012	GAVEA TRANSPO	37.105,13	C	0	0	37.105,13	C	0	
    2.2.01.03.00.00000	IMPOSTOS/CONTRI	165.316,95	C	0	1.013,14	166.330,09	C	1.013,14	C
    2.2.01.03.01.00000	IMPOSTOS/CONTR	165.316,95	C	0	1.013,14	166.330,09	C	1.013,14	C
    2.2.01.03.01.00037	ATUALIZA��O M	2.472,36	C	0	350,31	2.822,67	C	350,31	C
    2.2.01.03.01.00038	CONTIBUI��ES	86.082,28	C	0	0	86.082,28	C	0	
    2.2.01.03.01.00039	ATUALIZA��ES	4.952,57	C	0	662,83	5.615,40	C	662,83	C
    2.2.01.03.01.00040	INSS FATURAME	45.494,39	C	0	0	45.494,39	C	0	
    2.2.01.03.01.00041	MULTA DE TRIB	9.098,88	C	0	0	9.098,88	C	0	
    2.2.01.03.01.00042	MULTAS DE CON	17.216,47	C	0	0	17.216,47	C	0	
    2.2.01.06.00.00000	OUTRAS OBRIGA��	166.405,50	C	0	0	166.405,50	C	0	
    2.2.01.06.02.00000	SENTEN�A TRABA	166.405,50	C	0	0	166.405,50	C	0	
    2.2.01.06.02.00546	KELY EVELINE	98.691,00	C	0	0	98.691,00	C	0	
    2.2.01.06.02.00579	DENIS ELIAS	67.714,50	C	0	0	67.714,50	C	0	
    2.3.00.00.00.00000	PATRIMONIO LIQUID	765.284,52	D	0	0	765.284,52	D	0	
    2.3.04.00.00.00000	PREJUIZOS ACUMUL	12.541.488,42	D	0	0	12.541.488,42	D	0	
    2.3.04.01.00.00000	PREJUIZOS ACUMU	12.541.488,42	D	0	0	12.541.488,42	D	0	
    2.3.04.01.01.00000	PREJUIZOS ACUM	12.541.488,42	D	0	0	12.541.488,42	D	0	
    2.3.04.01.01.00001	PREJUIZOS ACU	12.541.488,42	D	0	0	12.541.488,42	D	0	
    2.3.05.00.00.00000	RETENCAO DE LUCR	11.776.203,90	C	0	0	11.776.203,90	C	0	
    2.3.05.01.00.00000	RETENCAO DE LUC	11.776.203,90	C	0	0	11.776.203,90	C	0	
    2.3.05.01.01.00000	RETENCAO DE LU	11.776.203,90	C	0	0	11.776.203,90	C	0	
    2.3.05.01.01.00001	RETENCAO DE L	11.776.203,90	C	0	0	11.776.203,90	C	0	
    3.0.00.00.00.00000	CUSTOS E DESPESAS	0		146.759,46	7.357,06	139.402,40	D	139.402,40	D
    3.1.00.00.00.00000	CUSTOS E DESPESAS	0		146.759,46	7.357,06	139.402,40	D	139.402,40	D
    3.1.01.00.00.00000	CUSTOS DOS SERVI	0		123.915,81	7.357,06	116.558,75	D	116.558,75	D
    3.1.01.01.00.00000	CUSTO TRAFEGO	0		92.489,04	6.573,95	85.915,09	D	85.915,09	D
    3.1.01.01.01.00000	CUSTO COM PESS	0		61.515,63	2.807,77	58.707,86	D	58.707,86	D
    3.1.01.01.01.00001	SALARIOS E OR	0		45.342,81	2.807,77	42.535,04	D	42.535,04	D
    3.1.01.01.01.00002	ABONO RETORNO	0		1.691,17	0	1.691,17	D	1.691,17	D
    3.1.01.01.01.00003	HORAS EXTRAS	0		1.555,95	0	1.555,95	D	1.555,95	D
    3.1.01.01.01.00006	13' SALARIO	0		4.033,74	0	4.033,74	D	4.033,74	D
    3.1.01.01.01.00008	FERIAS	0		7.496,30	0	7.496,30	D	7.496,30	D
    3.1.01.01.01.00013	ATESTADO MEDI	0		980,88	0	980,88	D	980,88	D
    3.1.01.01.01.00016	ADICIONAL NOT	0		414,78	0	414,78	D	414,78	D
    3.1.01.01.02.00000	ENCARGOS SOCIA	0		9.631,87	1.952,25	7.679,62	D	7.679,62	D
    3.1.01.01.02.00001	I.N.S.S	0		5.053,20	1.011,88	4.041,32	D	4.041,32	D
    3.1.01.01.02.00002	F.G.T.S	0		4.578,67	940,37	3.638,30	D	3.638,30	D
    3.1.01.01.03.00000	BENEFICIOS AO	0		20.140,51	1.813,93	18.326,58	D	18.326,58	D
    3.1.01.01.03.00001	ASSIST.MEDIC.	0		10.085,43	1.813,93	8.271,50	D	8.271,50	D
    3.1.01.01.03.00002	VALE ALIMENTA	0		10.055,08	0	10.055,08	D	10.055,08	D
    3.1.01.01.05.00000	SERVI�OS DE TE	0		1.201,03	0	1.201,03	D	1.201,03	D
    3.1.01.01.05.00005	AGUA	0		101,03	0	101,03	D	101,03	D
    3.1.01.01.05.00009	ALUGUEL DE IN	0		1.100,00	0	1.100,00	D	1.100,00	D
    3.1.01.02.00.00000	CUSTO MANUTEN�A	0		31.426,77	783,11	30.643,66	D	30.643,66	D
    3.1.01.02.01.00000	CUSTO COM PESS	0		18.950,91	0	18.950,91	D	18.950,91	D
    3.1.01.02.01.00001	SALARIOS E OR	0		14.887,50	0	14.887,50	D	14.887,50	D
    3.1.01.02.01.00002	ABONO RETORNO	0		79,55	0	79,55	D	79,55	D
    3.1.01.02.01.00003	HORAS EXTRAS	0		458,3	0	458,3	D	458,3	D
    3.1.01.02.01.00006	13' SALARIO	0		1.008,44	0	1.008,44	D	1.008,44	D
    3.1.01.02.01.00007	FERIAS	0		1.874,07	0	1.874,07	D	1.874,07	D
    3.1.01.02.01.00012	ATESTADO MEDI	0		429,41	0	429,41	D	429,41	D
    3.1.01.02.01.00015	ADICIONAL NOT	0		213,64	0	213,64	D	213,64	D
    3.1.01.02.02.00000	ENCARGOS SOCIA	0		2.915,45	313,9	2.601,55	D	2.601,55	D
    3.1.01.02.02.00001	I.N.S.S	0		1.529,12	134,96	1.394,16	D	1.394,16	D
    3.1.01.02.02.00002	F.G.T.S	0		1.386,33	178,94	1.207,39	D	1.207,39	D
    3.1.01.02.03.00000	BENEFICIOS AO	0		6.791,20	469,21	6.321,99	D	6.321,99	D
    3.1.01.02.03.00001	ASSIST.MEDIC.	0		2.684,19	469,21	2.214,98	D	2.214,98	D
    3.1.01.02.03.00002	VALE ALIMENTA	0		4.107,01	0	4.107,01	D	4.107,01	D
    3.1.01.02.05.00000	SERVI�OS DE TE	0		2.769,21	0	2.769,21	D	2.769,21	D
    3.1.01.02.05.00005	MANUTEN�AO E	0		2.610,99	0	2.610,99	D	2.610,99	D
    3.1.01.02.05.00011	ENERGIA ELETR	0		158,22	0	158,22	D	158,22	D
    3.1.02.00.00.00000	DESPESAS ADMINST	0		11.222,59	0	11.222,59	D	11.222,59	D
    3.1.02.01.00.00000	DESPESAS ADMINI	0		11.222,59	0	11.222,59	D	11.222,59	D
    3.1.02.01.03.00000	BENEFICIOS AO	0		159,96	0	159,96	D	159,96	D
    3.1.02.01.03.00003	SEGURO DE VID	0		159,96	0	159,96	D	159,96	D
    3.1.02.01.05.00000	SERVI�OS DE TE	0		5.568,52	0	5.568,52	D	5.568,52	D
    3.1.02.01.05.00007	MANUTEN�AO DE	0		1.588,74	0	1.588,74	D	1.588,74	D
    3.1.02.01.05.00013	AGUA	0		1.445,76	0	1.445,76	D	1.445,76	D
    3.1.02.01.05.00021	LICENCA DE SO	0		417,82	0	417,82	D	417,82	D
    3.1.02.01.05.00022	LICENCA USO I	0		2.116,20	0	2.116,20	D	2.116,20	D
    3.1.02.01.06.00000	DEPRECIA�OES/A	0		397,88	0	397,88	D	397,88	D
    3.1.02.01.06.00002	EQUIP./INFORM	0		397,88	0	397,88	D	397,88	D
    3.1.02.01.07.00000	GASTOS GERAIS	0		5.096,23	0	5.096,23	D	5.096,23	D
    3.1.02.01.07.00004	REFEI�OES E L	0		443,89	0	443,89	D	443,89	D
    3.1.02.01.07.00014	TAXAS MENSAL.	0		4.652,34	0	4.652,34	D	4.652,34	D
    3.1.03.00.00.00000	DESPESA FINANCEI	0		3.407,53	0	3.407,53	D	3.407,53	D
    3.1.03.01.00.00000	DESPESA FINANCE	0		3.407,53	0	3.407,53	D	3.407,53	D
    3.1.03.01.02.00000	JUROS DESPESAS	0		491,66	0	491,66	D	491,66	D
    3.1.03.01.02.00002	JUROS DE MORA	0		191,53	0	191,53	D	191,53	D
    3.1.03.01.02.00005	MULTAS DE MOR	0		300,13	0	300,13	D	300,13	D
    3.1.03.01.04.00000	ENCARGOS DE PA	0		2.915,87	0	2.915,87	D	2.915,87	D
    3.1.03.01.04.00003	ATUALIZA��O M	0		1.953,05	0	1.953,05	D	1.953,05	D
    3.1.03.01.04.00004	MULTAS DE MOR	0		962,82	0	962,82	D	962,82	D
    3.1.04.00.00.00000	CUSTO TRIBUTARIO	0		8.213,53	0	8.213,53	D	8.213,53	D
    3.1.04.01.00.00000	CUSTO TRIBUTARI	0		8.213,53	0	8.213,53	D	8.213,53	D
    3.1.04.01.01.00000	DESPESAS TRIBU	0		8.213,53	0	8.213,53	D	8.213,53	D
    3.1.04.01.01.00003	ICMS	0		3.019,86	0	3.019,86	D	3.019,86	D
    3.1.04.01.01.00009	INSS FATURAME	0		5.193,67	0	5.193,67	D	5.193,67	D
    4.0.00.00.00.00000	RECEITAS	0		0	265.261,08	265.261,08	C	265.261,08	C
    4.1.00.00.00.00000	RECEITAS OPERACIO	0		0	265.261,08	265.261,08	C	265.261,08	C
    4.1.01.00.00.00000	RECEITAS OPERACI	0		0	265.261,08	265.261,08	C	265.261,08	C
    4.1.01.01.00.00000	RECEITAS DE TRA	0		0	259.683,42	259.683,42	C	259.683,42	C
    4.1.01.01.03.00000	GAVEA	0		0	259.683,42	259.683,42	C	259.683,42	C
    4.1.01.01.03.00035	5356 - JABOTI	0		0	50.250,76	50.250,76	C	50.250,76	C
    4.1.01.01.03.00037	5582 - BH/JAB	0		0	205.441,51	205.441,51	C	205.441,51	C
    4.1.01.01.03.00039	OUTRAS RECEIT	0		0	1.043,65	1.043,65	C	1.043,65	C
    4.1.01.01.03.00059	5359 - JABOTI	0		0	2.947,50	2.947,50	C	2.947,50	C
    4.1.01.04.00.00000	OUTRAS RECEITAS	0		0	5.577,66	5.577,66	C	5.577,66	C
    4.1.01.04.01.00000	OUTRAS RECEITA	0		0	5.577,66	5.577,66	C	5.577,66	C
    4.1.01.04.01.00010	RECUPERACAO D	0		0	2.146,10	2.146,10	C	2.146,10	C
    4.1.01.04.01.00040	REVERS�O DE P	0		0	3.431,56	3.431,56	C	3.431,56	C
    `

    // convert csv_to_converted do csv
    let abc = csv_to_array(csv_to_converted);
    console.log(abc[0])


    return ''
    /*  let body_csv = jsonText.map(item => {
         let values = Object.values(item);
         let values_csv = values.toString().split('\t')
         return values_csv.join(',')
     })
     let csv = header_csv + '\n' + body_csv.join('\n')
     return csv; */
}
export const csvToJson = (file: any, mapkeyToNewKey: any) => {


}

function csv_to_array(csv: any) {
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
        let obj = {};
        let currentline = lines[i].split(",");
        for (let j = 0; j < headers.length; j++) {
            //@ts-ignore
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return result; //JavaScript object
}

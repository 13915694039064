import React, { useMemo, useState } from "react";

import Sidebar from "../../components/SideBarAtt/partials/Sidebar";
import Header from "../../components/SideBarAtt/partials/Header";
import WelcomeBanner from "../../components/SideBarAtt/partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../../components/SideBarAtt/partials/dashboard/DashboardAvatars";
import FilterButton from "../../components/SideBarAtt/partials/actions/FilterButton";
import Datepicker from "../../components/SideBarAtt/partials/actions/Datepicker";
import DashboardCard01 from "../../components/SideBarAtt/partials/dashboard/DashboardCard01";
import DashboardCard02 from "../../components/SideBarAtt/partials/dashboard/DashboardCard02";
import DashboardCard03 from "../../components/SideBarAtt/partials/dashboard/DashboardCard03";
import DashboardCard04 from "../../components/SideBarAtt/partials/dashboard/DashboardCard04";
import DashboardCard05 from "../../components/SideBarAtt/partials/dashboard/DashboardCard05";
import DashboardCard06 from "../../components/SideBarAtt/partials/dashboard/DashboardCard06";
import DashboardCard07 from "../../components/SideBarAtt/partials/dashboard/DashboardCard07";
import DashboardCard08 from "../../components/SideBarAtt/partials/dashboard/DashboardCard08";
import DashboardCard09 from "../../components/SideBarAtt/partials/dashboard/DashboardCard09";
import DashboardCard10 from "../../components/SideBarAtt/partials/dashboard/DashboardCard10";
import DashboardCard11 from "../../components/SideBarAtt/partials/dashboard/DashboardCard11";
import DashboardCard12 from "../../components/SideBarAtt/partials/dashboard/DashboardCard12";
import DashboardCard13 from "../../components/SideBarAtt/partials/dashboard/DashboardCard13";
import Banner from "../../components/SideBarAtt/partials/Banner";
import {
  FaBalanceScale,
  FaBalanceScaleLeft,
  FaHome,
  FaList,
  FaUser,
} from "react-icons/fa";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const menus = useMemo(
    () => [
      {
        name: "Home",
        icon: (size: number) => <FaHome size={size} />,
        href: "/dashboard",
      },
      {
        name: "Enviar balanço",
        onPress: () => {},
        href: "/balance",
        icon: (size: number) => <FaBalanceScale size={size} />,
      },
      {
        name: "Empresas",
        onPress: () => {},
        icon: (size: number) => <FaHome size={size} />,
        // href: "/company",
        subMenus: [
          {
            name: "Listar",
            onPress: () => {},
            href: "/company",
            icon: (size: number) => <FaList size={size} />,
          },
          {
            name: "Cadastrar",
            onPress: () => {},
            href: "/company/create",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Editar",
            onPress: () => {},
            href: "/company/edit",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Excluir",
            onPress: () => {},
            href: "/company/delete",
            icon: (size: number) => <FaUser size={size} />,
          },
        ],
      },
      {
        name: `Planos`,
        onPress: () => {},
        icon: (size: number) => <FaList size={size} />,
        href: "/plans",
      },
      {
        name: `Usuarios`,
        onPress: () => {},
        icon: (size: number) => <FaUser size={size} />,
        href: "/users",
      },
      {
        name: `Relatórios`,
        onPress: () => {},
        icon: (size: number) => <FaBalanceScaleLeft size={size} />,
        href: "/reports",
      },
    ],
    []
  );

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        menus={menus}
      />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Avatars */}
              <DashboardAvatars />

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                <FilterButton />
                {/* Datepicker built with flatpickr */}
                {/* Add view button */}
                <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                  <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Adicionar</span>
                </button>
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {/* Line chart (Acme Plus) */}
              <DashboardCard01 />
              {/* Line chart (Acme Advanced) */}
              <DashboardCard02 />
              {/* Line chart (Acme Professional) */}
              <DashboardCard03 />
              {/* Bar chart (Direct vs Indirect) */}
              <DashboardCard04 />
              {/* Line chart (Real Time Value) */}
              <DashboardCard05 />
              {/* Line chart (Sales Over Time) */}
              <DashboardCard08 />
              {/* Stacked bar chart (Sales VS Refunds) */}
              <DashboardCard09 />
              {/* Card (Customers) */}
              <DashboardCard10 />
              {/* Card (Recent Activity) */}
              <DashboardCard12 />
              {/* Card (Income/Expenses) */}
              <DashboardCard13 />
            </div>
          </div>
        </main>
        <Banner />
      </div>
    </div>
  );
}

export default Dashboard;

import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { usePlans } from "../../context/lib/plans";
import {
  FaBalanceScale,
  FaBalanceScaleLeft,
  FaHome,
  FaList,
  FaUser,
} from "react-icons/fa";
import Sidebar from "../../components/SideBarAtt/partials/Sidebar";
import { ToastContainer } from "react-toastify";
import Banner from "../../components/SideBarAtt/partials/Banner";
import Header from "../../components/SideBarAtt/partials/Header";

function PlansScreen() {
  const { getPlans, plans, plansLoading } = usePlans();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useLayoutEffect(() => {
    getPlans();
  }, []);

  const menus = useMemo(
    () => [
      {
        name: "Home",
        icon: (size: number) => <FaHome size={size} />,
        href: "/dashboard",
      },
      {
        name: "Enviar balanço",
        onPress: () => {},
        href: "/balance",
        icon: (size: number) => <FaBalanceScale size={size} />,
      },
      {
        name: "Empresas",
        onPress: () => {},
        icon: (size: number) => <FaHome size={size} />,
        // href: "/company",
        subMenus: [
          {
            name: "Listar",
            onPress: () => {},
            href: "/company",
            icon: (size: number) => <FaList size={size} />,
          },
          {
            name: "Cadastrar",
            onPress: () => {},
            href: "/company/create",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Editar",
            onPress: () => {},
            href: "/company/edit",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Excluir",
            onPress: () => {},
            href: "/company/delete",
            icon: (size: number) => <FaUser size={size} />,
          },
        ],
      },
      {
        name: `Planos`,
        onPress: () => {},
        icon: (size: number) => <FaList size={size} />,
        href: "/plans",
      },
      {
        name: `Usuarios`,
        onPress: () => {},
        icon: (size: number) => <FaUser size={size} />,
        href: "/users",
      },
      {
        name: `Relatórios`,
        onPress: () => {},
        icon: (size: number) => <FaBalanceScaleLeft size={size} />,
        href: "/reports",
      },
    ],
    []
  );

  useEffect(() => {}, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        menus={menus}
      />
      <div className="flex w-full justify-center align-middle items-center bg-background">
        <div className="max-w-screen-xl w-full  ">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Banner />
          <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
            <div className="flex items-center justify-center h-screen">
              <div className="px-10 py-6 rounded w-96  bg-card">
                <h1 className="my-4 text-2xl font-bold text-center text-dark ">
                  {plansLoading ? "Carregando..." : "Planos"}
                  {!plansLoading && plans.length === 0 && (
                    <div className="text-center">
                      <p className="my-4 text-base font-bold text-center text-dark ">
                        Nenhum plano cadastrado no momento
                      </p>
                    </div>
                  )}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default PlansScreen;

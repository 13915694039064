import { ReactElement } from "react";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "./lib/auth";
import { CompanyProvider } from "./lib/company";
import { FeatureFlagsProvider } from "./lib/flags";
import { PlansProvider } from "./lib/plans";
import { ThemeProvider } from "./lib/theme";

export const Providers = ({ children }: { children: ReactElement }) => {
  return (
    <CookiesProvider>
      <FeatureFlagsProvider>
        <AuthProvider>
          <PlansProvider>
            <CompanyProvider>
              <ThemeProvider>{children}</ThemeProvider>
            </CompanyProvider>
          </PlansProvider>
        </AuthProvider>
      </FeatureFlagsProvider>
    </CookiesProvider>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import "react-toastify/dist/ReactToastify.css";

import React, { useCallback, useLayoutEffect, useMemo } from "react";
import { read, utils } from "xlsx";
import { saveXLSX } from "../../utils/save";
import { processCSV, saveJsonInXLSX } from "../../utils/translate";
import DataTable from "react-data-table-component";
import Button from "../../components/Button";
import { Flip, toast, ToastContainer } from "react-toastify";
import {
  FaBalanceScale,
  FaBalanceScaleLeft,
  FaHome,
  FaList,
  FaUser,
} from "react-icons/fa";
import Sidebar from "../../components/SideBarAtt/partials/Sidebar";
import Header from "../../components/SideBarAtt/partials/Header";

export type json = {
  [key: string | number | symbol]: any;
}[];

const XSLX_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const CSV_TYPE = "text/csv";
const XLS_TYPE = "application/vnd.ms-excel";
const TXT_TYPE = "text/plain";
function Home() {
  // function to get document from input
  let [file, setFile] = React.useState<json>([]);
  const [mapkeyToNewKey, setMapkeyToNewKey] = React.useState<json>([]);
  const [fileName, setFileName] = React.useState<string>("");
  const [defaultFileName, setDefaultFileName] = React.useState<string>("");
  const [type, setType] = React.useState<"xlsx" | "xls" | "csv" | "txt">();
  const [preview, setPreview] = React.useState<boolean>(false);
  const [convertDandC, setConvertDandC] = React.useState<boolean>(true);
  const [removeDontSign, setRemoveDontSign] = React.useState<boolean>(true);

  const [selectedId, setSelectedId] = React.useState(0);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  const menus = useMemo(
    () => [
      {
        name: "Home",
        icon: (size: number) => <FaHome size={size} />,
        href: "/dashboard",
      },
      {
        name: "Enviar balanço",
        onPress: () => {},
        href: "/balance",
        icon: (size: number) => <FaBalanceScale size={size} />,
      },
      {
        name: "Empresas",
        onPress: () => {},
        icon: (size: number) => <FaHome size={size} />,
        // href: "/company",
        subMenus: [
          {
            name: "Listar",
            onPress: () => {},
            href: "/company",
            icon: (size: number) => <FaList size={size} />,
          },
          {
            name: "Cadastrar",
            onPress: () => {},
            href: "/company/create",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Editar",
            onPress: () => {},
            href: "/company/edit",
            icon: (size: number) => <FaUser size={size} />,
          },
          {
            name: "Excluir",
            onPress: () => {},
            href: "/company/delete",
            icon: (size: number) => <FaUser size={size} />,
          },
        ],
      },
      {
        name: `Planos`,
        onPress: () => {},
        icon: (size: number) => <FaList size={size} />,
        href: "/plans",
      },
      {
        name: `Usuarios`,
        onPress: () => {},
        icon: (size: number) => <FaUser size={size} />,
        href: "/users",
      },
      {
        name: `Relatórios`,
        onPress: () => {},
        icon: (size: number) => <FaBalanceScaleLeft size={size} />,
        href: "/reports",
      },
    ],
    []
  );

  const options_balance = [
    //'Transpes | CVP | Primavia | CSL',
    {
      name: "Tranpes",
      type: 0,
    },
    {
      name: "CVP",
      type: 0,
    },
    {
      name: "Primavia",
      type: 0,
    },
    {
      name: "CSL",
      type: 0,
    },
    {
      name: "TAGPLUS",
      type: 1,
      requeriment: ".csv",
    },
    {
      name: "Prolinx",
      type: 1,
      requeriment: ".csv",
    },
    {
      name: "Citbus",
      type: 2,
    },
  ];

  useLayoutEffect(() => {
    const isDevMode = process.env.NODE_ENV === "development";
    if (!isDevMode) {
      console.warn = () => {};
      console.log = () => {};
      console.error = () => {};
    }
  }, []);

  const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // @ts-ignore
    const file_type = e?.target?.files[0]?.type;

    // get name
    // @ts-ignore
    const name = e?.target?.files[0]?.name;

    console.log("file_type", name);

    let internal_selected = 0;
    // identificar o tipo do arquivo
    options_balance.forEach((option) => {
      if (name.includes(option.name)) {
        internal_selected = option.type;
        setSelectedId(option.type);
        if (option.requeriment) {
          if (file_type !== CSV_TYPE) {
            toast.error("O arquivo deve ser do tipo .csv");
            return;
          }
        }
      }
    });

    if (file_type === XSLX_TYPE) {
      setType("xlsx");

      // @ts-ignore
      let file_name = e?.target?.files[0].name;
      setDefaultFileName(file_name);
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          //@ts-ignore
          const data = e?.target.result;
          const workbook = read(data, { type: `array` });
          console.log(`wokr`, workbook);
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          console.log(data);
          const json = utils.sheet_to_json(worksheet) as json;
          setFile(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } else if (file_type === CSV_TYPE) {
      setType("csv");
      //@ts-ignore
      let file_name = e?.target?.files[0].name;
      setDefaultFileName(file_name);

      //@ts-ignore
      let file_total = e.target.files[0];

      const reader = new FileReader();
      reader.onload = function (e) {
        //@ts-ignore
        let text: string = e.target.result;
        if (internal_selected !== 1) {
          setFile(processCSV(text));
          return;
        }
        // tipagem para o TAGPLUS, separar so pra ele

        const date_index = text.search("Pág.:");
        let remover_text = text.substring(date_index - 135, date_index);
        let new_text = text.replaceAll(remover_text, "");
        // remover todos 'CONSULTAR SOLUCOES EMPRESARIAIS SS / Mastermaq Softwares.,,,,,,,,,,,,,'
        let texts_to_remove_all = [
          "CONSULTAR SOLUCOES EMPRESARIAIS SS / Mastermaq Softwares.,,,,,,,,,,,,,",
          "CÓD. CTA,CONTA,,,,NOME DA CONTA,,,SALDO ANTERIOR,DÉBITO,,CRÉDITO,SALDO ATUAL,",
          "E CONTRIBUIÇÕES,",
          "ÇÕES A REC,,",
          "ÇÕES A REC,",
          "EM INFORMATICA,",
        ];

        texts_to_remove_all.forEach((text) => {
          new_text = new_text.replaceAll(text, "");
        });
        new_text = new_text.replaceAll("IMPOSTOS,", "IMPOSTOS ");
        new_text = new_text.replaceAll("FÉRIAS, ", "FÉRIAS ");

        // find the word Pág.: 0000 in regex
        const regex = /Pág\.:\s\d{4},,/g;
        new_text = new_text.replaceAll(regex, "");
        // remove all the empty lines
        new_text = new_text.replaceAll("\n\n", "\n");
        // add at init
        // CÓD. CTA,CONTA,,,,NOME DA CONTA,,,SALDO ANTERIOR,DÉBITO,,CRÉDITO,SALDO ATUAL,
        new_text = `CÓD.CTA,CONTA,NOME DA CONTA,SALDO ANTERIOR,DÉBITO,CRÉDITO,SALDO ATUAL\n${new_text}`;

        // rem
        new_text = new_text.replaceAll(/,,,,,/g, ",");
        new_text = new_text.replaceAll(/,,/g, ",");

        // fIND ,\d\d [D]"
        const regex_replace = /,\d\d [D, C]/g;
        while (new_text.search(regex_replace) !== -1) {
          let init = new_text.search(regex_replace);
          const replace_char = ".";
          let replaced =
            new_text.substring(0, init) +
            replace_char +
            new_text.substring(init + 1);

          new_text = replaced;
        }
        const replace_chars = /,\d\d"/g;
        while (new_text.search(replace_chars) !== -1) {
          let init = new_text.search(replace_chars);
          const replace_char = ".";
          let replaced =
            new_text.substring(0, init) +
            replace_char +
            new_text.substring(init + 1);

          new_text = replaced;
        }

        // remove that last 393 lines
        new_text = new_text.substring(0, new_text.length - 393);
        console.log(new_text);

        setFile(processCSV(new_text, ",", true));
      };
      ///setFile(processCSV(text));

      //@ts-ignore
      reader.readAsText(file_total);
    } else if (file_type === TXT_TYPE) {
      setType("txt");
      // get file name of txt file from e
      //@ts-ignore
      let file_name = e.target.files[0].name;
      setFileName(file_name);
      // get content of txt file from e
      //@ts-ignore
      let file_total = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async function (e) {
        //@ts-ignore
        let text = e.target.result as string;
        if (internal_selected === 2) {
          // remover linhas que não tem valor
        }
        let text_2 = text;

        if (internal_selected === 2) {
          console.log(text_2);
          text_2 = text_2.slice(460 - 5, text_2.length - 1748);
          console.log(`text_2o final,}`);
          console.log(text_2);

          // encontrar 'PRAXIO TECNOLOGIA'
          const regex = /PRAXIO TECNOLOGIA/g;
          //find
          let index = text_2.search(regex);
          console.log(`index`, index);
          text_2
            .replace(/ /g, "_")
            // replace all ',' to '.'
            .replace(/,/g, ".")
            .split("\n") // split lines
            .map((line) => line.split(/\s+/).join(",")) // split spaces then join with ,
            .join("\n"); // rejoin lines

          // find the word 03.883.023/0001-80
          const regex_pagina = /03.883.023\/0001-80/g;

          while (text_2.search(regex_pagina) !== -1) {
            let init = text_2.search(regex_pagina);
            let abc = text_2.substring(init - 460, init + 18);
            // find in text_2 and remove
            text_2 = text_2.replace(abc, "");
          }

          console.log(`re,pvei abc`);

          // find and replace      Conta                      Descricao              Saldo Anterior          Debito         Credito       Saldo Atual         Resultado
          let count = 0;
          while (text_2.search("Resultado") !== -1) {
            let init = text_2.search("Resultado");
            let abc = text_2.substring(init - 140, init + 10);
            // find in text_2 and remove
            text_2 = text_2.replace(abc, "");
          }
          // add in init
          //      Conta                      Descricao              Saldo Anterior          Debito         Credito       Saldo Atual         Resultado
          let add_init = `     Conta                      Descricao              Saldo Anterior          Debito         Credito       Saldo Atual         Resultado`;
          text_2 = `${add_init}${text_2}`;
          console.log(`avbcd,}`);
          console.log(text_2);
        } else {
          text_2
            .replace(/ /g, "_")
            // replace all ',' to '.'
            .replace(/,/g, ".")
            .split("\n") // split lines
            .map((line) => line.split(/\s+/).join(",")) // split spaces then join with ,
            .join("\n"); // rejoin lines
        }

        if (internal_selected === 3) {
          text_2 = text_2.slice(408, text_2.length);
          console.log(`text2`, text_2);
        }
        text_2 = text_2
          .replace(/ /g, ",")
          // replace all ',' to '.'
          .replace(/,/g, ".")
          .split("\n") // split lines
          .map((line) => line.split(/\s+/).join(",")) // split spaces then join with ,
          .join("\n"); // rejoin lines

        console.log(`osh`);
        console.log(text_2);
        let csv_converted = processCSV(text_2);

        if (convertDandC) {
          let replacer = (text: string) => {
            let correctionFactor = text.includes("D") ? 1 : -1;

            let newValue = text.replace("D", "").replace("C", "");
            newValue = newValue.replace(/\./g, "").replace(",", ".");
            let value_corrected = parseFloat(newValue) * correctionFactor;
            newValue = value_corrected.toString();
            newValue =
              newValue.slice(0, newValue.length - 2) +
              "," +
              newValue.slice(newValue.length - 2);
            return newValue;
          };

          for (let i = 0; i < csv_converted.length; i++) {
            if (csv_converted[i][`Saldo_Anteri`]) {
              csv_converted[i]["Saldo_Anteri"] += csv_converted[i]["or"];
              csv_converted[i]["Saldo_Anteri"] = replacer(
                csv_converted[i]["Saldo_Anteri"]
              );
              delete csv_converted[i]["or"];
            }
            if (csv_converted[i][`Saldo_Atu`]) {
              csv_converted[i][`Saldo_Atu`] += csv_converted[i]["al"];
              csv_converted[i][`Saldo_Atu`] = replacer(
                csv_converted[i][`Saldo_Atu`]
              );
              delete csv_converted[i]["al"];
            }
            if (csv_converted[i][`Resulta`]) {
              csv_converted[i][`Resulta`] += csv_converted[i]["do"];
              csv_converted[i][`Resulta`] = replacer(
                csv_converted[i][`Resulta`]
              );
              delete csv_converted[i]["do"];
            }
          }
        }

        console.log(`text_2`);
        console.log(csv_converted);
        console.log(`final`);

        setFile(csv_converted);
      };
      //@ts-ignore
      reader.readAsText(file_total);
    }
    // fixme
    else if (file_type === XLS_TYPE) {
      setType("xls");
      //@ts-ignore
      let file_name = e?.target?.files[0].name;
      setDefaultFileName(file_name);

      var reader = new FileReader();
      //@ts-ignore
      let file_total = e.target.files[0];

      reader.onload = function (e) {
        //@ts-ignore
        var data = e.target.result;
        var workbook = read(data, {
          type: "binary",
        });
        workbook.SheetNames.forEach(function (sheetName: any) {
          // Here is your object
          console.log(workbook);
          /* var XL_row_object = utils.sheet_set_array_formula(
            workbook.Sheets[sheetName]
          );
          var json_object = JSON.stringify(XL_row_object); */
          //console.log(JSON.parse(json_object));
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file_total);
    }
  };

  const transcript = useCallback(() => {
    if (!file.length) return;
    const newFile = saveJsonInXLSX(file, mapkeyToNewKey, {
      removeCD: convertDandC,
    });
    saveXLSX(newFile, fileName, defaultFileName);
  }, [convertDandC, file, convertDandC, fileName, defaultFileName]);

  const previewTable = useCallback(() => {
    const newFile = saveJsonInXLSX(file, mapkeyToNewKey, {
      removeCD: convertDandC,
    });

    let keys = Object.keys(newFile[0]);
    let columns = keys.map((key) => {
      return {
        name: key,
        selector: key,
        sortable: true,
      };
    });

    // scroll to bottom
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 50);

    return (
      <div className="flex items-center justify-center w-full h-full max-w-screen-xl px-4 py-2 mx-auto mt-4 align-middle sm:px-6 lg:px-8">
        <DataTable
          key={`table_${convertDandC}`}
          //@ts-ignore
          columns={columns}
          data={newFile.slice(0, 4)}
        />
      </div>
    );
  }, [preview, file, mapkeyToNewKey, convertDandC]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        menus={menus}
      />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="items-center justify-center px-4 py-32 align-middle bg-card px-20 overscroll-y-auto">
          <div>
            <div className="flex flex-col items-center justify-center  ">
              <h1 className="mx-2 text-3xl text-center select-none text-violet-700">
                Lançamento de balancete
              </h1>
              <h1 className="mx-2 text-xl text-center text-black select-none px-9">
                Selecione o arquivo base
              </h1>
              <label className="mt-0.5 text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100">
                (Atualmente suportando apenas .xlsx, .xls, .csv, .txt)
              </label>
            </div>

            {/* <div className="flex justify-center items-center flex-col gap-2 py-4 mt-5">
              <h1 className="mx-2 text-xl text-center text-black select-none px-9 mb-3">
                Selecione o tipo de balancete que você deseja{" "}
              </h1>
              <Button
                onClick={() => setSelectedId(0)}
                className={`group w-96 justify-center align-middle items-center hover:bg-primary-600 ${
                  selectedId === 0 ? "bg-primary-500" : "bg-primary-100"
                }`}
              >
                <p
                  className={`text-sm text-center ${
                    selectedId !== 0 ? "text-dark" : "text-white"
                  } group-hover:text-white`}
                >
                  Tipo um <br></br>(
                  {options_balance.map((e, index) => {
                    let final =
                      index === options_balance.length - 4 ? "" : ", ";
                    if (e.type === 0) return `${e.name}${final}`;
                  })}
                  )
                </p>
              </Button>
              <Button
                onClick={() => {
                  setSelectedId(1);
                }}
                className={`group w-96 justify-center align-middle items-center hover:bg-primary-600  ${
                  selectedId === 1 ? "bg-primary-500" : "bg-primary-100"
                }`}
              >
                <p
                  className={`text-sm text-center  ${
                    selectedId !== 1 ? "text-dark" : "text-white"
                  } `}
                >
                  Tipo dois <br></br>(
                  {options_balance.map((e, index) => {
                    let final =
                      index === options_balance.length - 2 ? "" : ", ";
                    if (e.type === 1) return `${e.name}${final}`;
                  })}
                  )
                </p>
              </Button>

              <Button
                onClick={() => {
                  setSelectedId(2);
                }}
                className={`group w-96 justify-center align-middle items-center hover:bg-primary-600  ${
                  selectedId === 2 ? "bg-primary-500" : "bg-primary-100"
                }`}
              >
                <p
                  className={`text-sm text-center  ${
                    selectedId !== 2 ? "text-dark" : "text-white"
                  } `}
                >
                  Tipo três <br></br>(
                  {options_balance.map((e, index) => {
                    let final = index === options_balance.length - 1 ? "" : ",";
                    if (e.type === 2) return `${e.name}${final}`;
                  })}
                  )
                </p>
              </Button>
            </div> */}

            <div className="flex  gap-4 my-6 flex-col justify-center align-middle items-center">
              <h1 className="mx-2 text-xl text-center text-black select-none px-9 mb-3">
                Selecione o tipo de balancete que você deseja{" "}
              </h1>
              <form className="flex flex-col items-center justify-center text-center">
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                  // accept xlsx, xlx, csv
                  accept={`.xlsx, .csv, .txt, .xls`}
                  className="items-center self-center justify-center block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 "
                />
              </form>
            </div>
          </div>

          {
            <div
              className={`grid align-middle  gap-2 justify-center mt-5 grid-cols-2 sm:grid-cols-4 ${
                file.length >= 1
                  ? `${
                      Object?.keys(file[0]).length > 10
                        ? `grid-cols-3 md:grid-cols-4 lg:grid-cols-6`
                        : ""
                    }`
                  : ""
              }`}
            >
              {!!file &&
                file.length >= 1 &&
                Object?.keys(file[0])?.map((key, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-center col-span-1 row-auto align-middle "
                    >
                      <h1 className="mx-2 my-2 text-sm font-bold text-center text-black select-none px-9">
                        {key}
                      </h1>

                      <input
                        onChange={(e) => {
                          if (e.target.value?.length <= 0) {
                            let before_value = mapkeyToNewKey;
                            //@ts-ignore
                            delete before_value[key];
                            setMapkeyToNewKey({
                              ...before_value,
                            });
                            return;
                          }

                          setMapkeyToNewKey({
                            ...mapkeyToNewKey,
                            [key]: e.target.value,
                          });
                        }}
                        type="text"
                        //@ts-ignore
                        value={mapkeyToNewKey[key]}
                        placeholder="Novo texto"
                        className="w-full px-3 py-1 text-sm text-center text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                      />
                    </div>
                  );
                })}
            </div>
          }

          <div className="flex flex-col items-center justify-center mt-5 align-middle">
            <input
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              value={fileName}
              type="text"
              placeholder="Nome do arquivo"
              className="w-full max-w-xl px-4 py-2 mt-4 text-sm text-center border-2 rounded-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
            />

            <button
              key={`transcript_${convertDandC}`}
              onClick={transcript}
              className="flex items-center justify-center w-full max-w-xl py-2 mt-4 text-center origin-center rounded bg-violet-700"
            >
              <p className="text-white ">Salvar alterações</p>
            </button>

            {!!file && file.length >= 1 ? (
              <button
                onClick={() => setPreview((prev) => !prev)}
                className="flex items-center justify-center w-full max-w-xl py-2 mt-4 text-center origin-center bg-pink-600 rounded"
              >
                <p className="text-white ">
                  {!preview ? "Ativar Preview" : "Desativar Preview"}
                </p>
              </button>
            ) : null}

            {preview && previewTable()}
          </div>
        </div>
        <ToastContainer position="bottom-right" limit={3} />
      </div>
    </div>
  );
}

export default Home;
